const { get, set } = require('lodash');

const urlPaths = [
  'desktop.background.url',
  'desktop.background.imageUrl',
  'mobile.background.url',
  'mobile.background.imageUrl',
];
const imageSettingProps = {
  common: [...urlPaths, 'desktop.background.imageId', 'mobile.background.imageId'],
  restricted: urlPaths,
};

const logoTypeWhitelist = ['OmImage', 'OmFloatingImage'];

function getNameFromURL(url, withTimestamp = false) {
  if (withTimestamp) {
    return url?.split?.('/')?.splice?.(-1)?.[0];
  }

  return (
    url
      ?.split?.('/')
      ?.splice?.(-1)?.[0]
      ?.replace?.(/_\d{13}\./g, '.') ?? null
  );
}

const getSameImageProperties = (element, { currentImage, selectedImage }) => {
  const properties = currentImage?.imageId
    ? imageSettingProps.common
    : imageSettingProps.restricted;
  const matches = [];
  const name = getNameFromURL(currentImage?.imageUrl ?? currentImage);

  properties.forEach((path) => {
    const value = get(element, path, null);
    if (!value) return;
    const valueName = getNameFromURL(value);

    // split('_') remove timestamp and check only the name
    if (valueName.endsWith(`${name}`) || valueName === name) {
      matches.push({ path, value, replace: selectedImage?.imageUrl ?? selectedImage?.url });
      matches.push({
        path: path.replace(/(url|imageUrl)/giu, 'imageId'),
        value,
        replace: selectedImage?.imageId ?? selectedImage?.id,
      });
    }
  });

  return matches;
};

const reduceElements = ({ state, template, elements, pages, imageData, whitelist = [] }) => {
  const pageIds = pages?.map?.(({ uid }) => uid);

  return (state?.template?.elements ?? template?.elements ?? elements)
    .map((element, index) => {
      const isSamePage = pageIds
        ? pageIds.includes(element.type === 'OmPage' ? element.uid : element.pageId)
        : true;
      if (!isSamePage) return null;
      const allowed = whitelist.length ? whitelist.includes(element.type) : true;
      if (!allowed) return null;

      const matches = getSameImageProperties(element, imageData);

      return matches?.length ? { elementIndex: index, replacements: matches } : null;
    })
    .filter(Boolean);
};

const MAX_AREA = 7500;

const replaceImages = ({
  state,
  elements,
  template,
  pages,
  imageData,
  dispatch,
  shouldResetVisibility,
  whitelist,
}) => {
  const needReplacements = reduceElements({
    state,
    elements,
    template,
    pages,
    imageData,
    whitelist,
  });

  const object = state || template;

  const basePrefix = template ? '' : 'template.';
  needReplacements.forEach(({ elementIndex, replacements }) => {
    const pathPrefix = `${basePrefix}elements.${elementIndex}`;
    replacements.forEach(({ path, replace }) => {
      const uid = get(state, `${pathPrefix}.uid`);
      set(object, `${pathPrefix}.${path}`, replace);

      const { width, height } = imageData?.selectedImage ?? {};

      if (width && height) {
        const aspectRatio = width / height;
        // when aspectRatio is 1 or lower then we handling portrait or square image
        const areaMultiplier = aspectRatio <= 1 ? aspectRatio / 1.5 : aspectRatio;

        const newWidth = Math.ceil(Math.sqrt(MAX_AREA * areaMultiplier));
        const newMobileWidth = Math.ceil(newWidth / 1.5);

        set(object, `${pathPrefix}.desktop.smartSize.width`, newWidth);
        set(object, `${pathPrefix}.mobile.smartSize.width`, newMobileWidth);
      }

      if (shouldResetVisibility) {
        set(object, `${pathPrefix}.desktop.hidden`, false);
        set(object, `${pathPrefix}.mobile.hidden`, false);
      }

      if (uid) dispatch?.('updateElementStyle', uid);
    });
  });

  if (template) return template;
};

module.exports = {
  imageSettingProps,
  logoTypeWhitelist,
  getNameFromURL,
  getSameImageProperties,
  reduceElements,
  replaceImages,
};
